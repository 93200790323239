import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { Button, Modal } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import axios from "axios";
import { localDevelopmentPath } from "../tempText/env";
// 前端加入壓縮
import imageCompression from "browser-image-compression";

const UploadForm = () => {
  // loading and success
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [compressedFile, setCompressedFile] = useState(null);
  const [photoName, setPhotoName] = useState("");
  let newCompressedImage = null;
  const uploadPath = localDevelopmentPath + "uploadnew";
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    console.log(event.target.files[0]);
  };

  const handlePhotoNameChange = (event) => {
    const sanitizedValue = event.target.value.replace(/\D/g, ""); // 只保留数字字符
    setPhotoName(sanitizedValue);
  };

  const handleFormSubmit = async (event) => {
    setIsSubmitting(true);
    event.preventDefault();

    // 以下是2024 1021 增加圖片壓縮功能
    // 增加壓縮條件
    const options = {
      maxSizeMB: 0.1, // 圖片最大1MB
      maxWidthOrHeight: 1920, // 限制圖片最大寬高
      useWebWorker: true, // 使用 web worker 進行壓縮
    };
    try {
      // 使用库进行图片压缩
      const compressedFile = await imageCompression(selectedFile, options);

      console.log(
        "Compressed file size:",
        compressedFile.size / 1024 / 1024,
        "MB"
      );

      newCompressedImage = new File([compressedFile], selectedFile.name, {
        type: selectedFile.type,
      });

      // 將壓縮後的圖片存到state
      setCompressedFile(newCompressedImage);
    } catch (error) {
      console.error("Error during image compression:", error);
    }

    const formData = new FormData();
    console.log(typeof newCompressedImage);
    formData.append("image", newCompressedImage, photoName);
    const entries = Array.from(formData.entries());
    console.log(entries);
    axios
      .post(uploadPath, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((data) => {
        console.log(data);
        setShowSuccess(true);
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setIsSubmitting(false);
      });

    // 使用选定的文件和照片名称进行上传逻辑
    // 可以调用后端 API 进行上传处理
  };
  const handleClose = () => {
    setShowSuccess(false);
  };

  return (
    <Form onSubmit={handleFormSubmit}>
      {/* <input type="file" onChange={handleFileChange} /> */}
      <Form.Group
        as={Col}
        md="4"
        controlId="validationFormik101"
        className="position-relative"
      >
        <Form.Label>請輸入編號(例如:12)</Form.Label>
        <Form.Control
          type="number"
          name="name"
          onChange={handlePhotoNameChange}
        />
        <Form.Control.Feedback tooltip>Looks good!</Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="position-relative mb-3">
        <Form.Label>File</Form.Label>
        <Form.Control
          type="file"
          required
          name="image"
          onChange={handleFileChange}
        />
      </Form.Group>
      {/* <input type="text" value={photoName} onChange={handlePhotoNameChange} /> */}
      <Button type="submit" variant="outline-dark" disabled={isSubmitting}>
        {isSubmitting ? "正在上傳照片..." : "上傳照片"}
      </Button>
      <Modal show={showSuccess} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>上傳照片</Modal.Title>
        </Modal.Header>
        <Modal.Body>{photoName}號照片上傳成功</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            關閉
          </Button>
        </Modal.Footer>
      </Modal>
    </Form>
  );
};

export default UploadForm;
